body {
  margin: 0;
  padding: 0;
  background-color: #fafafa !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.logo_class_header {
  width: 222px;
  margin-top: 6px;
  margin-left: 7px;
}
.logo_class_footer {
  width: 146px;
  margin-top: 31px;
}
.haeder_logo_search_container {
  height: 133px;
  background-color: #bac5c6;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about_main_container {
  padding-bottom: 70px;
  height: 100vh;
}
.logo {
  font-size: 39px;
  color: #fff;
  margin-left: 50px;
}
.search_con {
  display: flex;
  align-items: center;
  padding-right: 50px;
}
.nav_container {
  padding-top: 15px;
  padding-bottom: 50px;
  position: relative;
  z-index: 100;
}
.nav_itmes_con {
  display: flex;
  align-content: center;
  justify-content: center;
}
.nav_itmes_con li {
  list-style-type: none;
}
.nav_itmes_con_li {
  border-right: 1px solid #403e3c;
}
.nav_item {
  padding-bottom: 22px;
  cursor: pointer;
  font-weight: 600;
  padding: 0px 22px 23px;
  color: #256f8d !important;
  margin: 5px 0;
  font-size: 20px;
  text-transform: uppercase;
  font-family: Dinamit;
}
.nav_itmes_con_li:last-child {
  border-right: none !important;
}
.nav_item:hover {
  color: #000;
}
.nav_itmes_con_li:hover .nav_pod_category_con-1 {
  display: block !important;
}

.nav_itmes_con_li:hover .nav_pod_category_con-0 {
  display: block !important;
}

.nav_pod_category_con-0 {
  margin-left: -92px;
}

.nav_pod_category_con-1 {
  margin-left: 23px;
}

.nav_pod_category_con-0,
.nav_pod_category_con-1 {
  border: 1px solid #256f8d;
  background-color: #f4f3f2;
  text-align: left;
  line-height: 1.5;
  letter-spacing: 1.8px;
  color: #999;
  font-family: Dinamit;
  padding-left: 0;
  padding: 7px;
  position: absolute;
  margin-top: 10px;
  display: none;
}
.nav_pod_category_con_link {
  color: #4f8080;
}

.article_by_category {
  width: 157px;
}

.header_profile .MuiSvgIcon-root {
  fill: #fff !important;
}

/* .nav_pod_category_con_link:hover {
  color: #c80000;
} */

.nav_pod_category_con_item {
  cursor: pointer;
  font-weight: 500;
}
.big_chapter_container {
  overflow: hidden;
  height: 355px;
  background-color: #f4f3f2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.big_chapter_title_container {
  padding: 28px;
  text-align: left;
}
.title_con {
  margin: 0 10px 10px 0;
  color: #615e5a;
  font-size: 26px;
  line-height: 32px;
  font-weight: 400;
}
.title_p {
  margin: 5px 50px 8px 0;
  font-size: 19px;
  line-height: 22px;
  color: #000;
}
.item_con_content {
  color: black;
  text-decoration: underline;
}
.item_con_content:hover {
  text-decoration: none;
  color: black;
}
.con_content_header {
  color: #403e3c;
  font-size: 20px;
  margin-bottom: 7px;
}
.con_content_p {
  font-weight: 500;
}
.con_content_date_and_category {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.con_content_category {
  font-weight: 500;
  margin-left: 10px;
}
.item_con_image {
  margin-right: 35px;
  width: 136px;
  height: 102px;
}

.more_chapter_container {
  text-align: left;
  margin-top: 50px;
  /* height: 3053px; */
}
.more_chapters_title {
  font-size: 26px;
  line-height: 32px;
  font-weight: 500;
  padding-bottom: 27px;
  color: #615e5a;
}
.more_chapter_category {
  text-decoration: underline;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: cadetblue;
}
.more_chapter_title {
  color: #000;
  border-bottom: 1px solid #bac5c6;
  margin-bottom: 0.5em;
  padding-bottom: 0.5em;
  font-weight: 400;
  margin: 5px 0;
  font-size: 19px;
  line-height: 24px;
  height: 80px;
  overflow: hidden;
}
.more_chapter_p {
  color: #000;
  padding-bottom: 7px;
}
.more_chapter_date {
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: cadetblue;
}

.for_chapter_hover {
  cursor: pointer;
}
.article_href:hover {
  text-decoration: none !important;
}
.article_href:hover .more_chapter_title {
  text-decoration: underline;
}
.logo_small {
  color: #44414d;
  font-size: 27px;
}
.privacy_and_staff_con {
  text-align: left;
  font-size: 13px;
  line-height: 1.2;
  margin-right: 80px;
}
.privacy_link {
  font-weight: 600;
  color: #fff;
}
.privacy_link:hover {
  color: #d2d7d3;
}
.footer_container {
  height: 248px;
  background: #bac5c6;
  color: #fff;
  padding-left: 85px;
  padding-right: 85px;
}
.social_svg {
  height: 32px;
  width: 36px;
}
.social_container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.post_main_container {
  text-align: left;
  margin-top: 54px;
}
.posts_type {
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 1.375;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.5px;
  text-transform: none;
  color: #000;
  margin: 0 0 20px;
  padding: 0;
}
.posts_title {
  color: #256f8d;
  margin: 0 0 10px;
  padding: 0;
  display: block;
  font-size: 30px;
  line-height: 1.1;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
}
.posts_by {
  margin: 7px 0 15px 0;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 1px;
  font-weight: 300;
}
.post_date {
  display: flex;
  align-items: center;
  font-size: 10px;
  padding: 0;
  margin: 0 0 10px;
  font-family: Dinamit;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: #999;
  position: relative;
  border: 0;
  font-size: 9px;
  font-weight: 500;
  margin: 0;
}
.arrow {
  padding: 8px 0 10px 30px;
  margin-bottom: 10px;
  background-image: url("./images/radar.png");
  background-repeat: no-repeat;
  background-position: top left;
}
.arrow span {
  font-weight: 600;
}
.radar_ul {
  padding-left: 46px;
  margin-top: 0px;
}
.radar_ul2 {
  padding: 0;
  margin-top: 0px;
}
.radar_li {
  padding: 4px 0;
  list-style-type: none;
  font-size: 15px;
  line-height: 17px;
  font-weight: 400;
  color: #8b8580;
}
.radar_li2 {
  padding: 11px 0;
  list-style-type: none;
  font-size: 15px;
  line-height: 17px;
  font-weight: 400;
  color: #8b8580;
  display: flex;
  justify-content: center;
}
.radar_li_link {
  color: #777;
}
.radar_li_link2 {
  color: #777;
  padding-left: 10px;
}
.radar_li_link:hover {
  color: #777;
}
.radar_li_link2:hover {
  color: #777;
}
.readers_radar_container {
  display: flex;
  text-align: left;
  align-items: flex-end;
  justify-content: center;
}
.readers_radar2_container {
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
}
.radar_header {
  font-size: 25px;
}
.post_text_container {
  /* border: 1px solid #256f8d; */
  padding: 10px;
  border-radius: 3px;
  max-height: 500px;
  margin-bottom: 10px;
  text-align: left;
  font-size: 20px;
  line-height: 1.375;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.5px;
  text-transform: none;
  color: #000;
  margin: 30px 0 20px;
  padding: 0;
  white-space: pre-line;
  font-family: sans-serif;
}
.vh_sk {
  margin-top: 100px;
  margin-bottom: 50px;
  height: 100vh;
}
.link_about {
  margin-left: 5px;
}
.first_char {
  font-size: 67px;
  font-weight: 700;
}
.about_con {
  /* height: 100vh; */
  padding-bottom: 57px;
}
.about_con_title {
  margin-top: 10px;
  line-height: 1.5;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: #256f8d;
  font-size: 32px;
  font-weight: 500;
}
.about_title {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 300;
  margin: 16px 0;
  clear: both;
}
.about_txt {
  padding-top: 36px;
  font-size: 15px;
  line-height: 1.375;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.5px;
  text-transform: none;
  color: #000;
  margin: 0 0 15px 0;
  text-align: left;
}

.auth_con {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
  margin-bottom: 15px;
}
.auth_con a {
  color: #000;
}
.auth_con a:hover {
  color: #000;
}
.css-1hwfws3 {
  height: 56px;
  background: #fafafa;
}
.css-1wy0on6 {
  background: #fafafa;
}
.textarea {
  width: 100%;
  background-color: transparent;
  border: 1px solid #303f9f;
  border-radius: 3px;
  height: 204px;
}
.table_txt {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 144px;
}
.select .css-1pcexqc-container {
  width: 223px;
}
.sub_categories_edit_dp .css-1pcexqc-container {
  width: 195px;
  border-bottom: 1px solid black !important;
  border: none;
}
.sub_categories_edit_dp .css-bg1rzq-control {
  border: none;
}
.sub_categories_edit_dp .css-bg1rzq-control {
  background-color: transparent !important;
}
.sub_categories_edit_dp .css-1hwfws3 {
  background-color: transparent;
}
.sub_categories_edit_dp .css-1wy0on6 {
  background-color: transparent;
}
.grid_css {
  margin-top: 50px;
  display: flex;
  width: 1228px;
  padding-bottom: 80px;
  justify-content: center;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background-color: #fff;
  padding-top: 80px;
}
.video_checkbox_two {
  padding: 10px;
}
.gallery_title {
  font-family: Dinamit;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: #999;
  font-size: 23px;
  font-weight: 500;
  margin-bottom: 33px;
}
.grid_css_two {
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  display: flex;
  padding-bottom: 80px;
  justify-content: center;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background-color: #fff;
  padding-top: 33px;
}
.temp:hover {
  text-decoration: none !important;
}
.files input {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  padding: 120px 0px 85px 35%;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
}
.files input:focus {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  border: 1px solid #92b0b3;
}
.files {
  position: relative;
}
.files:after {
  pointer-events: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 50px;
  right: 0;
  height: 56px;
  content: "";
  background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
}
.color input {
  background-color: #f1f1f1;
}
.files:before {
  position: absolute;
  bottom: -13px;
  left: 0;
  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
  content: " or drag it here. ";
  display: block;
  margin: 0 auto;
  color: #2ea591;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}
.sk_m_a {
  margin: auto;
}
.vh_container {
  min-height: calc(100vh - 200px);
}
.video_checkbox {
  background-color: #fafafa;
  text-align: left;
}
.back_btn_style {
  margin-right: 8px !important;
}
.back_btn_style_two {
  margin-right: 8px !important;
  margin-bottom: 30px !important;
}
.back_btn_style:hover {
  color: #fff !important;
}
.back_btn_style_two:hover {
  color: #fff !important;
}
.articles_title {
  font-style: normal;
  text-transform: none;
  color: #000;
  font-family: Dinamit;
  font-weight: 200;
  color: #000;
  margin: 0;
  font-size: 40px;
  margin-bottom: 57px;
}
.articles_con {
  margin-bottom: 50px;
  margin-top: 50px;
}
.big_chapter_image_con {
  display: block;
  border: 1px solid transparent;
  margin-right: 5px;
  position: relative;
  overflow: hidden;
  padding-bottom: 32%;
  width: 121%;
}
.big_chapter_image_con_img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  margin-right: 5px;
}
.more_chapter_pic_con {
  display: block;
  border: 1px solid transparent;
  margin-right: 5px;
  position: relative;
  overflow: hidden;
  padding-bottom: 75%;
  width: 100%;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.trending_container {
  max-height: 383px;
  overflow-y: scroll;
}
.more_chapter_image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  margin-right: 5px;
}
.article_view {
  display: block;
  border: 1px solid transparent;
  margin-right: 5px;
  position: relative;
  overflow: hidden;
  /* padding-bottom: 60%; */
  width: 100%;
}
.article_view_image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  margin-right: 5px;
}
/* .article_background:nth-child(1){
  background-color: #293352;
  padding: 20px;
}
.article_background:nth-child(2){
  background-color: #4e84c4;
  padding: 20px;
}
.article_background:nth-child(3){
  background-color: #ababb1;
  padding: 20px;
}
.article_background:nth-child(4){
  background-color: #c3d7a4;
  padding: 20px;
}
.article_background:nth-child(5){
  background-color: #faafa8;
  padding: 20px;
}
.article_background:nth-child(6){
  background-color: #41242b;
  padding: 20px;
} */
.article_block {
  padding: 1.25em;
  background-color: #fff;
}
.add_btn {
  margin-bottom: 40px !important;
}
.all_content {
  border-top: 1px solid #fff;
  margin-top: 14px;
  padding-top: 4px;
  font-size: 0.75em;
  color: #fff;
  text-align: left;
}
.form_width {
  width: 1228px;
}
.form_width_sub_categories {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 1228px;
}
.form_width_categories {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 1228px;
}
.cancel_save_btns {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 127px;
}
.footer_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.article_big_container {
  padding-bottom: 100px;
}
.videos_container {
  text-align: center;
}
.sk_m {
  margin-top: 20px;
}
.trending_title_videos {
  font-size: 10px;
  padding: 0;
  margin-top: 50px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: #a51c30;
  position: relative;
  display: inline-block;
  border: 0;
  font-size: 9px;
}
.trending_title_gallery {
  margin-bottom: 22px;
  font-size: 10px;
  padding: 0;
  margin-top: 50px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: #a51c30;
  border: 0;
  font-size: 9px;
  text-align: center;
}
.trending_title {
  font-size: 10px;
  padding: 0;
  margin-top: 224px !important;
  margin: 0 0 10px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: #a51c30;
  position: relative;
  display: inline-block;
  border: 0;
  font-size: 9px;
}
.pending_ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.pending_li {
  counter-increment: numbered-counter;
}
.pending_li_a {
  border-top: 1px solid #eaeaea;
  display: block;
  padding: 15px 0;
  position: relative;
}
.pending_li_a:before {
  content: "";
  display: block;
  font-family: Dinamit;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  font-style: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: -1px;
  left: 0;
  padding-top: 7px;
  content: counter(numbered-counter);
  z-index: 30;
  width: 30px;
  color: #a51c30 !important;
  text-align: center;
  display: inline-block;
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
}
.pending_li_a::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 30px;
  height: 1px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-color: #a51c30;
}
.pending_li_a:hover {
  color: inherit;
}
.card_content {
  text-align: left;
}
.pending_article {
  display: flex;
  justify-content: space-between;
}
.pending_article_div {
  width: 100%;
}
.pending_article_div_h2 {
  text-align: left;
  margin-top: 13px;
  font-size: 15px;
  line-height: 1.375;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.5px;
  text-transform: none;
  color: #000;
  padding: 0;
}
.pending_article_view {
  display: block;
  border: 1px solid transparent;
  margin-right: 5px;
  position: relative;
  overflow: hidden;
  padding-bottom: 22%;
  width: 54%;
}
.pending_article_view_image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  margin-right: 5px;
}
.card:hover {
  text-decoration: none;
  color: inherit;
}
.circle_progress_con {
  text-align: center;
  width: 100%;
  height: 100vw;
}
.categories_skeleton {
  border-right: 1px solid black;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
}
.icon_btn_user {
  margin-left: 15px;
}
.header_profile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 195px;
}
.article_views {
  padding-left: 10px;
  padding-right: 10px;
}
.profile_name {
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
  text-transform: uppercase;
  font-family: Dinamit;
}
.articles_by_cat_container {
  text-align: left;
  /*height: 100vh;*/
}
.articles_by_title {
  font-size: 64px;
  font-weight: 200;
  color: #000;
}
.articles_by_list {
  margin: 0 0 40px;
}
.article_by_link {
  display: flex;
  align-items: center;
  justify-self: center;
}

.article_by_link:hover {
  color: black;
}
.list_item_by {
  border-top: 1px solid #eaeaea;
  padding-top: 30px;
  padding-bottom: 30px;
}
.article {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.article-image__meta {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
.textarea_label {
  color: grey;
}
.article_textarea {
  padding: 20px;
  width: 100%;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #c0c0c0;
}
.article-image__title {
  margin: 0 0 10px;
  padding: 0;
  color: black;
  display: block;
  font-size: 30px;
  line-height: 1.1;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
}
.article-image__subtitle {
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 1.375;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.5px;
  text-transform: none;
  color: #000;
  margin: 0 0 20px;
  padding: 0;
}
.article-image__cat-and-date {
  margin-top: auto;
  margin-bottom: -4px;
}
.article-image__posted-on {
  font-size: 10px;
  padding: 0;
  margin: 0 0 10px;
  font-family: Dinamit;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  color: #999;
  position: relative;
  display: inline-block;
  border: 0;
  font-size: 9px;
  font-weight: 500;
  margin: 0;
}
.big_auth {
  font-family: Dinamit !important;
  margin-right: 13px !important;
}
.big_time {
  font-size: 10px !important;
  padding: 0 !important;
  margin-right: 12px !important;
  margin-left: 9px !important;
  font-family: Dinamit !important;
  font-weight: 500 !important;
  letter-spacing: 1.8px !important;
  text-transform: uppercase !important;
  color: #999 !important;
  position: relative !important;
  display: inline-block !important;
  border: 0 !important;
  font-size: 9px !important;
  font-weight: 500 !important;
}
.css-1hwfws3 {
  background-color: #fff !important;
}
.timestamp {
  /* font-size: 10px !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: 1.8px !important;
  text-transform: uppercase !important;
  color: #999 !important; */
}
.latest_p_bg_title {
  border-top: 1px solid #80808073 !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
  color: #999 !important;
  padding-bottom: 15px !important;
  padding-top: 15px !important;
}
.latest_p_txt {
  border-top: 1px solid #80808073 !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: 1px !important;
  text-transform: uppercase !important;
  color: #282020 !important;
  padding-bottom: 15px !important;
  padding-top: 15px !important;
}
.latest_p {
  padding-bottom: 15px !important;
  padding-top: 15px !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: 1.8px !important;
  text-transform: uppercase !important;
  color: #999 !important;
}
.latest_h2 {
  font-family: Dinamit !important;
  font-weight: 700 !important;
}
.video_name {
  font-size: 17px;
  background-color: #bac5c6;
  text-align: left;
  font-weight: 400;
  padding: 5px;
  margin-top: 15px;
}
.login_button {
  margin-right: 10px !important;
}
.login_back {
  width: 500px;
}
.grid_list {
  width: 640px;
  height: 507px;
  transform: translateZ(0);
}
@media (max-width: 1200px) {
  .trending_title {
    margin-top: 175px !important;
  }
  .trending_container {
    width: 327px;
    float: right;
  }
  .pending_article_div_h2 {
    font-size: 11px;
  }
  .grid_list {
    /* width: 377px !important; */
  }
  .post_text_container {
    margin: 78px 0 20px !important;
  }
}
@media (max-width: 992px) {
  .card_media {
    height: 300px !important;
  }
  .big_chapter_media {
    height: 400px !important;
  }
  .card_con {
    margin-bottom: 30px;
  }
  .trending_title {
    margin-top: 53px !important;
  }
  .pending_article_view {
    padding-bottom: 14% !important;
    width: 34% !important;
  }
  .grid_list {
    width: 100% !important;
  }
  .trending_container {
    width: 100% !important;
  }
}
@media (max-width: 576px) {
  .profile_name {
    font-size: 11px !important;
  }
  .login_button {
    font-size: 10px !important;
    margin-right: 5px !important;
  }
  .auth_con {
    width: 87% !important;
  }
  .login_back {
    width: 100% !important;
  }
  .logo_class_footer {
    width: 100px !important;
    margin-top: 11px !important;
  }
  .logo_class_header {
    width: 172px !important;
    margin-top: 29px !important;
  }
  .search_con {
    padding-right: 16px !important;
  }
  .header_profile {
    width: 156px !important;
  }
  .header_funcs_container {
    display: flex;
    flex-direction: column;
    /*align-items: flex-end;*/
  }
  .search_con {
    width: 155px;
  }
  .footer_container {
    height: 176px !important;
    padding: 28px !important;
  }
  .all_content {
    font-size: 0.6em !important;
  }
  .social_svg {
    height: 20px !important;
    width: 26px !important;
  }
  .articles_by_title {
    font-size: 50px !important;
  }
  .article-image__title {
    font-size: 27px !important;
  }
  .article-image__subtitle {
    font-size: 12px !important;
  }
  .privacy_and_staff_con {
    font-size: 11px !important;
  }
}
@media (min-width: 600px) {
  .drawer_content {
    margin-left: -240px !important;
  }
}
